// FIX_ME:
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
import moment, { isMoment, Moment } from "moment/moment";
import { MomentInput, utc } from "moment";
import timezones from "timezones.json";

const ONE_MINUTE_IM_MS = 60000;
const MINUTES_IN_HOUR = 60;

export const calcMinutes = (start: number, finish: number): number =>
  (finish - start) / ONE_MINUTE_IM_MS;

/**
 * Formats a moment object into a string in the "h:mm a" format (e.g., 8:45 p.m.).
 * changeTimeFormat Analog
 *
 * @param {moment.Moment} dateString - The moment object to format.
 * @returns {string} The formatted time in "h:mm a" format (e.g., "8:45 p.m.").
 */
export const formatMomentToAmPm = (dateString: moment.Moment) =>
  dateString.format("h:mm a");

export const changeTimeFormat = (date: string | Date | Moment) =>
  buildDateTime(date)
    .format("h:mm a")
    .replace("pm", "p.m.")
    .replace("am", "a.m.")
    .replace(" ", "\u0020");

export const getTimeIn12hFormat = changeTimeFormat;

export const isInRange = (
  time: Date | number,
  start: Date | number,
  end: Date | number,
) => start <= time && time <= end;
export const isAfterRange = (time: Date | number, end: Date | number) => time >= end;

export function changeDateFormat(date: string | Date | Moment) {
  return buildDateTime(date)?.format("ll");
} // format Jan 23, 2023

export const buildDateTime = (
  dateTime: Date | Moment | string,
  date?: Date | Moment | string,
) => {
  const result = isMoment(dateTime) ? dateTime : moment(dateTime);

  if (date) {
    const correctDate = moment(date);
    result
      .year(correctDate.year())
      .month(correctDate.month())
      .date(correctDate.date());
  }

  return result;
};

/**
 * Converts a given date to a Moment.js object.
 *
 * @param {Date} dateWithoutTime - The date object to be converted, which should not include time.
 * @returns {moment.Moment} A Moment.js object representing the provided date.
 */
export const convertSelectedDateToMoment = (dateWithoutTime: Date): moment.Moment =>
  moment(dateWithoutTime);

/**
 * Converts a given date or date string to a Moment.js object,
 * setting seconds and milliseconds to zero.
 *
 * @param {Date|string} dateWithTime - The date or date string to be converted.
 * @returns {moment.Moment} A Moment.js object representing the provided date with seconds and milliseconds set to zero.
 */
export const convertSelectedTimeToMoment = (
  dateWithTime: Date | string,
): moment.Moment => moment(dateWithTime).seconds(0).milliseconds(0);

// arguments have correct tz date time
export const replaceDate = (val: Moment, targetDate: Moment) => {
  const result = val.clone();

  return result
    .year(targetDate.year())
    .month(targetDate.month())
    .date(targetDate.date());
};

export const calcEndTime = (val: Moment, eventStart: Moment) => {
  const end = replaceDate(val, eventStart); // cloned
  if (end < eventStart) {
    end.add(1, "days");
  }

  return end;
};

export const calcMinEventStartDate = (startTime: Moment) =>
  startTime.clone().add(1, "days");

/**
 * Converts a Moment.js object to a native JavaScript Date object.
 * If no Moment object is provided, it returns undefined.
 *
 * @param {Moment} [moment] - The Moment.js object to be converted. Optional.
 * @returns {Date|undefined} - The corresponding Date object, or undefined if no Moment object is provided.
 */
export const convertMomentToDate = (moment?: Moment): Date | undefined => {
  if (!moment) return undefined;

  return moment.clone().toDate();
};

/**
 * TimePicker всегда показывает время по локали пользователя и не умеет работать с
 * указанным часовым поясом. Поэтому нам надо сдвигать часовой пояс, чтобы имитировать
 * на стороне пользователя вывод времени соответствующий его часовому поясу.
 *
 * @param dateTime {Moment} - Moment object in UTC-0
 * @returns {Date} - Date with shift for TimePicker
 */
export const prepareDateTimeForTimePicker = (dateTime: Moment): Date => {
  // Используем "-", т.к. JS возвращает значение с противоположным знаком.
  const timezoneShift = -new Date().getTimezoneOffset() / MINUTES_IN_HOUR;
  const timePickerDateMoment = momentTimezoneShift(dateTime, timezoneShift);

  return timePickerDateMoment.toDate();
};

export const convertUtcToMomentTz = (
  utcValue: MomentInput | undefined,
  timezone?: string,
) => {
  if (!utcValue) return undefined;

  // NOTE: EventSettings/Basic section crushes for utcValue === null
  return timezone ? moment.utc(utcValue).tz(timezone) : moment.utc(utcValue);
};

// preview (create step 3), landing, lobby
export const convertUtcToLocal = (utcValue: string) => utc(utcValue).local();

/**
 * Converts a Moment.js date object from the local time zone to UTC in a specified time zone.
 *
 * @param {Moment} [value] - The Moment.js date object to be converted. If not provided, an empty string is returned.
 * @param {string} [timeZone=''] - The IANA time zone identifier to convert the date to. If not provided, an empty string is returned.
 * @returns {string} The UTC formatted date string if both value and timeZone are provided; otherwise, an empty string.
 *
 * @example
 * const utcDate = convertMomentToUtcTz(moment(), 'America/New_York');
 * console.log(utcDate); // Outputs the current date in UTC format based on the specified time zone
 */
export const convertMomentToUtcTz = (
  value: Moment | undefined,
  timeZone: string,
): string => {
  if (!value) return "";

  // В value данные в таймзоне браузера, поэтому надо сместить это время в таймзону пользователя
  const convertedValue = momentTimezoneShift(value, timeZone);

  return convertedValue.utc().format();
};

export const formatTime = (time?: Moment) =>
  time
    ? time
        .format("h:mm a")
        .replace("pm", "p.m.")
        .replace("am", "a.m.")
        .replace(" ", "\u00A0")
    : "";

/**
 * Formats a given Moment.js date object into a readable string.
 *
 * @param {Moment} date - The Moment.js date object to be formatted.
 * @returns {string} The formatted date string in the "ll" format.
 */
export const formatDate = (date?: Moment): string => date?.format("ll") || "";

/**
 * Conversion IANA to Windows timezone
 * @todo Надо полностью перейти на IANA и отказаться от Windows формата. Тогда этот конвертер перестанет быть нужен.
 * @param {string} ianaTimeZone - Timezone in IANA format
 * */
export const convertIanaToWindows = (ianaTimeZone: string): string | null => {
  const timezone = timezones.find((tz) => tz.utc.includes(ianaTimeZone));

  return timezone ? timezone.value : null;
};

/**
 * Conversion Windows to IANA timezone
 * @todo Надо полностью перейти на IANA и отказаться от Windows формата. Тогда этот конвертер перестанет быть нужен.
 * @param {string} windowsTimeZone - Timezone in Цштвщцы format
 * */
export const convertWindowsToIana = (
  windowsTimeZone?: string,
): string | null | undefined => {
  if (!windowsTimeZone) return undefined;

  const timezone = timezones.find((tz) => tz.value === windowsTimeZone);

  return timezone ? timezone.utc[0] : null;
};

/**
 * Shifts the given moment value to a specified time zone while preserving the original time.
 *
 * @param {Moment} value - The moment object to be shifted.
 * @param {string | number} timeZone - The target time zone to shift to (e.g., 'America/New_York' or an offset in hours).
 * @returns {Moment} A new moment object representing the same local time in the specified time zone.
 */
const momentTimezoneShift = (value: Moment, timeZone: string | number): Moment => {
  if (typeof timeZone === "string") {
    const originalTime = value.format("HH:mm");

    return moment.tz(`${value.format("YYYY-MM-DD")} ${originalTime}`, timeZone);
  }

  return value.clone().utcOffset(timeZone * 60, true);
};

/**
 * Converts an ISO string to the specified time zone.
 *
 * @param {string} isoStr - A date string in ISO 8601 format.
 * @param {string} timezone - The name of the time zone (e.g., 'America/New_York').
 * @returns {string} The date and time in the format 'YYYY-MM-DDTHH:mm:ss' in the specified time zone.
 *
 * @example
 * const result = convertIsoToTimezone("2023-11-01T12:00:00Z", "Europe/Moscow");
 * console.log(result); // '2023-11-01T15:00:00'
 */
export const convertIsoToTimezone = (isoStr: string, timezone: string): string => {
  const utcDate = moment.utc(isoStr);
  const targetDate = utcDate.tz(timezone);

  return targetDate.format("YYYY-MM-DDTHH:mm:ss");
};
