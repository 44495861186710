import { useEffect, useRef } from "react";

export const GoogleTagManager = () => {
  // Используем useRef для хранения флага, что элементы уже добавлены
  const gtmScriptAdded = useRef(false);
  const gtmNoscriptAdded = useRef(false);

  useEffect(() => {
    const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

    if (gtmId && !gtmScriptAdded.current && !gtmNoscriptAdded.current) {
      // Script for head
      const script = document.createElement("script");
      script.innerHTML = `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),
              dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;
          j.src='https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');
      `;
      document.head.insertBefore(script, document.head.firstChild);
      gtmScriptAdded.current = true;

      // Script for body
      const noscript = document.createElement("noscript");
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.insertBefore(noscript, document.body.firstChild);
      gtmNoscriptAdded.current = true;
    }
  }, []);

  return null;
};
