// FIX_ME:
/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Text } from "@evvve/ui-kit";
import {
  ChangePasswordModal,
  DeletePasswordModal,
  SetPasswordModal,
} from "src/close-part/features/Modals";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/hooks/useStoreHooks";
import { useTabletType } from "src/hooks";
import style from "./style.module.scss";
import DeleteAccount from "../DeleteAccount";
import { LoaderWrap } from "../../../../../shared-features/LoaderWrap/LoaderWrap";
import Honeycomb from "../../../../../shared-features/Honeycomb/Honeycomb";

const {
  wrap,
  wrap_inner,
  title,
  content,
  email,
  section,
  form,
  hr_bottom,
  fullwidth,
  changePassword,
  section_label,
} = style;

const AccessDetails = () => {
  const navigate = useNavigate();
  const isTablet = useTabletType();
  const user = useAppSelector((state) => state.user.userInfo);

  return (
    <Honeycomb type="a" className={wrap}>
      <div className={wrap_inner}>
        <div className={title}>
          <Text size={[6, 6, 5]} font="inter" bold>
            {" "}
            Access details{" "}
          </Text>
        </div>
        {user ? (
          <div className={content}>
            <div className={form}>
              <div className={section}>
                <div className={section_label}>
                  <Text size="m">Email</Text>
                  {!isTablet && (
                    <Button
                      id="change-email-button-desktop"
                      type="flat"
                      theme="violet"
                      size="xs"
                      onClick={() => navigate("/profile-settings/set-new-email")}
                    >
                      Change email
                    </Button>
                  )}
                </div>
                <div className={email}>
                  <Text size="s" color="grayscale600" className={style.email_text}>
                    {user?.email}
                  </Text>
                  {isTablet && (
                    <Button
                      id="change-email-button-mobile"
                      type="flat"
                      theme="violet"
                      size="xs"
                      onClick={() => navigate("/profile-settings/set-new-email")}
                    >
                      Change email
                    </Button>
                  )}
                </div>
              </div>
              <div className={section}>
                <Text size="m">Password</Text>
                <Text size="s" color="grayscale600">
                  You can set a permanent password if you don't want to use temporary
                  login codes.
                </Text>
              </div>
              <div className={fullwidth}>
                {!user?.is_using_password && <SetPasswordModal />}
                <div className={changePassword}>
                  {user?.is_using_password && (
                    <>
                      <ChangePasswordModal />
                      <DeletePasswordModal />
                    </>
                  )}
                </div>
              </div>
              <hr className={`${hr_bottom} solid`} />
              <DeleteAccount />
            </div>
          </div>
        ) : (
          <LoaderWrap />
        )}
      </div>
    </Honeycomb>
  );
};

export default AccessDetails;
