import React from "react";
import { useGetUserQuery } from "src/store/User/api";
import { NewSpeakerAtTheEventMetadata } from "src/store/messenger";
import { NotificationTemplate } from "../notification-template";

type Props = {
  metadata: NewSpeakerAtTheEventMetadata;
};

const NewSpeakerAtEvent = ({ metadata }: Props) => {
  const {
    speaker_id: speakerId,
    event_id: eventId,
    event_name: eventTitle,
  } = metadata.data;

  const { data } = useGetUserQuery({ userId: speakerId });
  const userName = data
    ? `${data.user.first_name} ${data.user.last_name}`
    : "Unknown";

  return (
    <NotificationTemplate
      title="New speaker at the event"
      event={{ title: eventTitle, link: `/catalog/event/${eventId}` }}
      user={data && { name: userName, avatar_url: data.user.avatar_url }}
    />
  );
};

export default NewSpeakerAtEvent;
